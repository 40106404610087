import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/layout/Layout'
import { Seo as SEO } from '../../components/Seo'
const PageBanner = React.lazy(() =>
  import('../../components/common/PageBanner')
)
const NewsEventDetailSection = React.lazy(() =>
  import('../../components/newsAndEvents/NewsEventDetailSection')
)
const CategoryList = React.lazy(() =>
  import('../../components/common/CategoryList')
)

const NewsEventDetail = ({ data }) => {
  const { post, banner } = data
  const links = [
    { label: 'Home', url: '/' },
    { label: 'News and Events', url: '/news-and-events' },
    {
      label: `${post.fieldType[0].toUpperCase()}${post.fieldType.substring(1)}${!post.fieldType.endsWith('s') ? "s" : ""}`,
      url: `/news-and-events/${post.slug}`,
    },
  ]
  const categories = [
    { label: 'All', url: '/news-and-events' },
    { label: 'News', url: '/news-and-events/news' },
    { label: 'Events', url: '/news-and-events/events' },
    { label: 'Journals', url: '/news-and-events/journals' },
  ]

  return (
    <Layout>
      <PageBanner
        title={"News and Events"}
        links={links}
        bannerImage={banner.gatsbyImageData}
      />
      <section className="container grid grid-cols-1 gap-y-6 py-8 md:py-12">
        <CategoryList 
          categories={categories}
          activeCategory={categories.find(category => category.label === `${post.fieldType[0].toUpperCase()}${post.fieldType.substring(1)}${!post.fieldType.endsWith('s') ? "s" : ""}`).url}
          type="row"
        />
        <NewsEventDetailSection post={post} />
      </section>
    </Layout>
  )
}

export default NewsEventDetail

export const Head = ({ data }) => {
  return (
    <SEO
      title={data.post.title}
      description={data.post.description?.description}
    />
  )
}

export const query = graphql`
  query getNewsDetail($language: String!, $slug: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    post: contentfulNewsEvent(slug: { eq: $slug }) {
      id
      title
      author
      description {
        description
      }
      publishDate(formatString: "DD/MM/YYYY")
      image {
        gatsbyImageData
      }
      content {
        raw
        # references {
        #  contentful_id
        #  __typename
        #  gatsbyImageData
        #  title
        # }
      }
      fieldType
    }
    banner: contentfulAsset(title: { eq: "News Event Banner" }) {
      gatsbyImageData
    }
  }
`
